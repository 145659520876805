<template>
  <div>
    <div class="item-print">
      <img
        v-if="
          bill.storeType === STORE_TYPE.DDV && !bill.storeName.includes('Vertu')
        "
        src="media/logos/bill-print-logo.png"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      />
      <img
        v-if="bill.storeName.includes('Vertu')"
        src="media/logos/vertu.jpg"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      />
      <img
        v-if="bill.storeType === STORE_TYPE.VDD"
        src="media/logos/vdd_hori_logo.png"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      />
      <!-- thông tin cửa hàng-->
      <div class="heading">
        <!-- tên chi nhánh -->
        <p class="bill-content">{{ bill.storeName }}</p>
        <!-- địa chỉ chi nhánh -->
        <p class="bill-content">
          {{ bill.storeAddress }}
        </p>
        <!-- số hotline -->
        <!-- <p class="bill-content">Hotline: {{ getPhoneNumberByStoreType }}</p> -->
        <!-- kết thúc thông tin cửa hàng-->
      </div>
      <h5
        class="text-bold text-center"
        style="font-size: 26px; margin-left: 30px"
      >
        Hóa Đơn Bán Hàng
      </h5>
      <!-- thông tin khách hàng -->
      <div class="customer font-size-print text-500">
        <div>
          <span class="text-title">Khách Hàng</span>
          <span style="margin-left: 4px">{{ bill.customerName }}</span>
        </div>
        <div>
          <span class="text-title">Điện thoại</span>
          <span style="margin-left: 4px">{{ bill.customerPhone }}</span>
        </div>
        <div v-if="bill.customerType && showDDVInfo">
          <span class="text-title">Thành viên</span>
          <span style="margin-left: 4px">{{ bill.customerType.name }}</span>
        </div>
        <div
          style="display: flex; align-items: baseline"
          v-if="bill.customerAddress"
        >
          <span class="text-title">Địa chỉ</span>
          <span style="margin-left: 8px; line-height: 1.375">{{
            bill.customerAddress
          }}</span>
        </div>
        <div v-if="showDDVInfo">
          <span class="text-title">Số ID</span>
          <span class="text-bold-600" style="margin-left: 4px">{{
            bill.billNumber
          }}</span>
        </div>
        <div>
          <span class="text-title">Ngày tạo</span>
          <span class="text-bold-600" style="margin-left: 4px">{{
            bill.createdAt
          }}</span>
        </div>
      </div>
      <!-- sản phẩm -->
      <div class="product-section">
        <table class="product-table">
          <thead>
            <tr>
              <th class="text-left">
                <span class="text-bold-600 font-size-print">SL</span>
              </th>
              <th class="text-center">
                <span class="text-bold-600 font-size-print">Giá niêm yết</span>
              </th>
              <!-- <th class="text-center">
                <span class="text-bold-600 font-size-print">Chiết khấu</span>
              </th> -->

              <th class="text-right">
                <span class="text-bold-600 font-size-print">Thành tiền</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <div
              class="list-product"
              v-for="(item, index) in bill.listBillItem"
              :key="index"
            >
              <tr>
                <td colspan="4">
                  <span class="label-color font-size-print">
                    <i
                      style="color: rgb(24, 28, 50)"
                      class="fas fa-gift"
                      v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    ></i>
                  </span>
                  <span class="bill-content font-size-print">{{
                    item.name
                  }}</span>
                </td>
              </tr>
              <tr v-if="item.imeiCode">
                <td colspan="3">
                  <span class="font-size-imei" style="font-style: italic">
                    IMEI: {{ item.imeiCode }}
                  </span>
                </td>
              </tr>
              <tr
                v-if="
                  item.billItemType !== BILL_ITEM_TYPE.PRODUCT_BONUS &&
                    item.listedPrice > item.price
                "
              >
                <!-- <td colspan="4">
                  <span class="font-size-print">
                    Giá niêm yết:
                  </span>
                  <span class="listed-price font-size-print listed-price">
                    {{ convertPrice(item.listedPrice) }}
                  </span>
                </td> -->
              </tr>
              <tr>
                <td class="text-left">
                  <span class="bill-content font-size-print">{{
                    item.quantity
                  }}</span>
                </td>
                <td class="text-center">
                  <span
                    class="bill-content font-size-print"
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    >0</span
                  >
                  <!-- trường hợp giá niêm yết nhỏ hơn giá bán -->
                  <span
                    class="bill-content font-size-print"
                    v-else-if="item.listedPrice < item.price"
                    >{{ convertPrice(item.price) }}</span
                  >
                  <span v-else class="bill-content font-size-print">{{
                    convertPrice(item.listedPrice)
                  }}</span>
                </td>
                <!-- <td class="text-center">
                  <div>
                    <span
                      class="bill-content font-size-print"
                      v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                      >0</span
                    >
                    <template v-else>
                      <p class="bill-content font-size-print">
                        {{
                          convertPrice(
                            actualDiscountAmount({
                              listPrice: item.listedPrice,
                              price: item.totalPrice,
                            }),
                          )
                        }}
                      </p>
                      <p class="bill-content font-size-print">
                        {{
                          persentDiscountProduct({
                            listPrice: item.listedPrice,
                            price: item.totalPrice,
                          })
                        }}%
                      </p>
                    </template>
                  </div>
                </td> -->
                <td class="text-right">
                  <span
                    class="bill-content font-size-print"
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    >0</span
                  >
                  <!-- <span v-else class="bill-content font-size-print">{{
                    convertPrice(item.totalPrice)
                  }}</span> -->
                  <span
                    class="bill-content font-size-print"
                    v-else-if="item.listedPrice < item.price"
                    >{{ convertPrice(item.price * item.quantity) }}</span
                  >
                  <span
                    v-else-if="item.listedPrice >= item.price"
                    class="bill-content font-size-print"
                    >{{ convertPrice(item.listedPrice * item.quantity) }}</span
                  >
                  <span v-else class="bill-content font-size-print">{{
                    convertPrice(item.totalPrice)
                  }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="bill-content font-size-print text-center"
                    >Giảm KM</span
                  >
                </td>
                <td class="text-center">
                  <span
                    class="bill-content font-size-print"
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                  >
                    100%
                  </span>

                  <span
                    class="bill-content font-size-print"
                    v-else-if="item.listedPrice < item.price"
                    >{{
                      percentDiscountProduct({
                        listedPrice: item.price,
                        price: item.listedPrice,
                        quantity: item.quantity,
                        discountAmount: item.discountAmount,
                        discountType: item.discountType,
                        customerDiscountType: item.customerDiscountType,
                        customerDiscountAmount: item.customerDiscountAmount,
                      })
                    }}%</span
                  >

                  <span v-else class="bill-content font-size-print">
                    {{
                      percentDiscountProduct({
                        listedPrice: item.listedPrice,
                        price: item.price,
                        quantity: item.quantity,
                        discountAmount: item.discountAmount,
                        discountType: item.discountType,
                        customerDiscountType: item.customerDiscountType,
                        customerDiscountAmount: item.customerDiscountAmount,
                      })
                    }}%</span
                  >
                </td>
                <td class="text-right">
                  <span
                    class="bill-content font-size-print"
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                  >
                    0
                  </span>
                  <!-- trường hợp giá bán lớn hơn giá niêm yết thì lấy giá bán tính -->
                  <span
                    class="bill-content font-size-print"
                    v-else-if="item.listedPrice < item.price"
                  >
                    <!-- {{
                      convertPrice(
                        actualDiscountAmount({
                          listPrice: item.price,
                          price: item.totalPrice,
                        }),
                      )
                    }} -->
                    {{
                      convertPrice(
                        actualDiscountAmount({
                          listedPrice: item.price,
                          price: item.listedPrice,
                          quantity: item.quantity,
                          discountAmount: item.discountAmount,
                          discountType: item.discountType,
                          customerDiscountType: item.customerDiscountType,
                          customerDiscountAmount: item.customerDiscountAmount,
                        }),
                      )
                    }}
                  </span>
                  <span v-else class="bill-content font-size-print text-center"
                    >-
                    <!-- {{
                      convertPrice(
                        actualDiscountAmount({
                          listPrice: item.listedPrice,
                          price: item.totalPrice,
                        }),
                      )
                    }} -->
                    {{
                      convertPrice(
                        actualDiscountAmount({
                          listedPrice: item.listedPrice,
                          price: item.price,
                          quantity: item.quantity,
                          discountAmount: item.discountAmount,
                          discountType: item.discountType,
                          customerDiscountType: item.customerDiscountType,
                          customerDiscountAmount: item.customerDiscountAmount,
                        }),
                      )
                    }}
                  </span>
                </td>
              </tr>
              <tr style="border-bottom: 0.5px dashed black">
                <td colspan="2">
                  <!-- <span class="bill-content font-size-print text-center">
                  </span> -->
                </td>
                <td class="text-right">
                  <span
                    class="bill-content font-size-print"
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                  >
                    0
                  </span>
                  <span
                    v-else
                    class="bill-content font-size-print text-center"
                    >{{ convertPrice(item.totalPrice) }}</span
                  >
                </td>
              </tr>
            </div>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" class="text-left">
                <span class="font-size-print text-right">Tổng:</span>
              </td>
              <!-- <td class="text-center font-size-print text-bold-600">
                {{ convertPrice(bill.sumTotalListedPrice) }}
              </td>
              <td class="text-center font-size-print text-bold-600">
                {{
                  persentDiscountProduct({
                    listPrice: bill.sumTotalListedPrice,
                    price: bill.sumUnitAmount,
                  })
                }}%
              </td> -->
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.sumTotalListedPrice) }}
              </td>
              <!-- sumTotalListedPrice -->
            </tr>
            <!-- <tr>
              <td colspan="1" class="text-left">
                <span class="font-size-print text-right">Tổng:</span>
              </td>
              <td class="text-center font-size-print text-bold-600">
                {{ convertPrice(bill.sumTotalListedPrice) }}
              </td>
              <td class="text-center font-size-print text-bold-600">
                {{
                  persentDiscountProduct({
                    listPrice: bill.sumTotalListedPrice,
                    price: bill.sumUnitAmount,
                  })
                }}%
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.totalAmount) }}
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-print text-left" colspan="3">
                <span>Tổng tiền (sản phẩm):</span>
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.sumUnitAmount) }}
              </td>
            </tr> -->
            <tr>
              <td colspan="2" class="text-left">
                <span class="font-size-print text-right"
                  >Giảm CK (Sản phẩm):</span
                ><br />
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(totalDiscountAmount || 0) }}
              </td>
            </tr>
            <tr v-if="showDDVInfo">
              <td colspan="2" class="text-left">
                <span class="font-size-print text-right"
                  >Giảm CK ({{ bill?.customerType?.name.toUpperCase() }}):</span
                ><br />
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.sumTotalDiscountCustomer || 0) }}
              </td>
            </tr>
            <!-- <tr>
              <td colspan="2" class="text-left">
                <span class="font-size-print text-right"
                  >Tổng sau CK (Sản phẩm &
                  {{ bill?.customerType?.name.toUpperCase() }}):</span
                ><br />
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.sumTotalAmount || 0) }}
              </td>
            </tr> -->
            <tr>
              <td colspan="2" class="text-left">
                <span class="font-size-print text-right">Giảm CK Hóa Đơn:</span
                ><br />
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.discountAmount || 0) }}
              </td>
            </tr>
            <!-- <tr>
              <td class="font-size-print text-left" colspan="3">
                <span>Tiền đã cọc:</span>
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.depositAmount) }}
              </td>
            </tr>
            <tr v-if="getTotalTradein > 0">
              <td class="font-size-print text-left" colspan="3">
                <span>Tiền thu cũ:</span><br />
                <strong class="font-size-print" style="font-size: 12px"
                  >(IMEI: {{ bill.tradeIn.productIMEI }})</strong
                >
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(getTotalTradein) }}
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-print text-left" colspan="3">
                <span>Phí ship:</span>
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.shipFee) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span></span>Tổng tiền (sau CK):
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(totalAmountAfterDiscount || 0) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span></span>Tiêu điểm:
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.pointUse) }}
              </td>
            </tr>
            <tr v-if="bill.conversionFee">
              <td class="font-size-print text-left" colspan="3">
                <span></span>Phí chuyển đổi:
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.conversionFee) }}
              </td>
            </tr> -->
            <tr style="font-size: 18px">
              <td colspan="2" class="text-left text-bold-600">
                <span style="font-style: italic"></span> Tổng tiền (+ phí):
              </td>
              <td class="text-right text-bold-600">
                {{ convertPrice(bill.totalPay) }}
              </td>
            </tr>
            <tr class="font-size-print">
              <td class="text-left" colspan="3">
                <span>Tổng tiền bằng chữ: {{ bill.textAmount }}</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <hr style="margin-top: 10px" />
      <!-- tích điểm -->
      <div class="loyalty font-size-print text-500" style="width: 100%">
        <hr />
        <div style="width: 100%; display: flex; justify-content: space-between">
          <span class="text-title">Số tiền tích lũy thành viên</span>
          <span>{{ convertPrice(bill.totalPay) }}</span>
        </div>
        <!-- <div v-if="bill.addPoint > 0">
          <span class="text-title">Số điểm được tích</span>
          <span>{{ bill.addPoint }}</span>
        </div>
        <div>
          <span class="text-title">Điểm tích lũy còn lại</span>
          <span>{{ bill.customerPoint }}</span>
        </div>
        <div>
          <span class="text-title">Tiêu điểm tích lũy</span>
          <span>{{ bill.subtractPoint }} điểm</span>
        </div>
        <div>
          <span class="text-title">Tiền đã cọc</span>
          <span>{{ convertPrice(bill.depositAmount) }}</span>
        </div>
        <div v-if="bill.transferFromOrder > 0">
          <span>Tiền đã chuyển khoản</span>
          <span>{{ convertPrice(bill.transferFromOrder) }}</span>
        </div>
        <div v-if="bill.moneyCredit > 0">
          <span>Tiền đã quẹt thẻ</span>
          <span>{{ convertPrice(bill.moneyCredit) }}</span>
        </div>
        <div v-if="bill.moneyInstallment > 0">
          <span>Tiền đã trả góp:</span>
          <span>{{ convertPrice(bill.moneyInstallment) }}</span>
        </div>
        <div>
          <span class="text-title">CK trên hóa đơn</span>
          <span>{{ convertPrice(bill.discountValue) }}</span>
        </div>
        <div v-if="bill.customerShipFee > 0">
          <span>Phí ship</span>
          <span>{{ convertPrice(bill.customerShipFee) }}</span>
        </div> -->
      </div>
      <!-- Thông tin bảo hành -->
      <hr style="margin-top: 10px" />
      <div class="warranty font-size-print text-500">
        <div style="display: flex; justify-content: space-between">
          <span>Bảo hành: 1800.6729</span
          ><span v-if="showDDVInfo">Khiếu nại: 1800.6306</span>
        </div>
      </div>
      <hr style="margin-top: 10px" />
      <!-- thông tin người bán -->
      <div
        class="font-size-print text-500"
        style="margin-bottom: 10px; line-height: 28px"
      >
        <div class="d-flex justify-content-between">
          <span class="text-title">Tư vấn</span>
          <span>{{ bill.saleName }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="text-title">Thu ngân</span>
          <span>{{ bill.cashierName }}</span>
        </div>
        <!-- <div>
          <span class="text-title">Địa chỉ cửa hàng</span>
          <span>{{ bill.storeAddress }}</span>
        </div> -->
      </div>
      <!-- <img
        v-if="
          bill.storeType === STORE_TYPE.DDV &&
            bill.storeId !== 101851 &&
            bill.storeId !== 101852
        "
        src="media/logos/logo-gdn-03.png"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      /> -->

      <div v-if="showDDVInfo">
        <hr />

        <!-- <div>
          <p class="font-size-print text-500 text-center">
            Ưu Đãi Đặc Quyền D.MEMBER
          </p>
          <ul style="color: #000; font-size: 14px; padding-left: 12px;">
            <li>
              Thu cũ đổi mới: Tặng thêm đến
              <span style="font-weight: bold;">4,5 triệu</span>
            </li>
            <li>
              Mua điện thoại | Máy tính bảng | Laptop | Phụ kiện công nghệ: Giảm
              đến <span style="font-weight: bold;"> 6%</span>
            </li>
            <li>
              Sinh nhật khách hàng: Tặng ngay voucher
              <span style="font-weight: bold;">500.000đ</span>
            </li>
          </ul>
        </div> -->
        <div
          style="
            border: 2px solid #000;
            padding: 10px;
            border-radius: 8px;
            line-height: 2;
          "
        >
          <p class="font-size-print text-500 text-center">
            Ưu Đãi Đặc Quyền Thành Viên
          </p>
          <ul style="color: #000; font-size: 14px; padding-left: 12px">
            <li>
              Thu cũ đổi mới: Tặng thêm đến
              <span style="font-weight: bold">4,5 triệu</span>
            </li>
            <li>
              Mua điện thoại | Máy tính bảng | Laptop | Phụ kiện công nghệ: Giảm
              đến <span style="font-weight: bold">6%</span>
            </li>
            <li>
              Sinh nhật khách hàng: Tặng ngay voucher lên đến
              <span style="font-weight: bold;">500.000đ</span>
            </li>
          </ul>
        </div>
        <div style="display: inline-flex; margin-top: 10px">
          <qr-code
            :text="linkViewWarranty"
            :size="120"
            error-level="L"
          ></qr-code>
          <b-col>
            <ul
              style="
                color: #000;
                font-size: 14px;
                list-style: none;
                padding: 0;
                margin: 0;
                line-height: 1.9;
              "
            >
              <li>Quét mã để tải app</li>
              <li>Xem đặc quyền thành viên</li>
              <li>Sử dụng đặc quyền thành viên</li>
              <li>Tra cứu lịch sử mua sắm, bảo hành</li>
            </ul>
          </b-col>
        </div>
        <hr />
      </div>
      <div
        v-if="showDDVInfo"
        style="border:2px solid #000; border-radius: 8px; line-height: 2; margin-top:15px"
      >
        <table style="width: 100%; ">
          <tr
            class="header-vdd"
            style="
              text-align: center;
              font-size: 14px;
              border-bottom: 2px solid #000;
              background: #000;
            "
          >
            <td style="font-weight: bold; color: #fff">VIỆN DI ĐỘNG</td>
            <td
              style="
                border-left: 2px solid #fff;
                font-weight: bold;
                color: #fff;
              "
            >
              <span>BẢO HÀNH - SỬA CHỮA</span> <br />
              <span>ĐIỆN THOẠI LAPTOP</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding: 10px; font-weight: 400">
              <ul
                style="color: rgb(0, 0, 0); font-size: 14px; padding-left: 12px"
              >
                <li>
                  Giảm thêm
                  <span style="font-weight: bold">5% đến (300,000)</span> địch
                  vụ bảo hành sửa chữa VIỆN DI ĐỘNG
                </li>
                <li>
                  <span style="font-weight: bold">Miễn phí</span> sấy khô, kiểm
                  tra lỗi Điện Thoại - Laptop
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <hr style="margin-top: 20px !important" />
      <div class="text-align-center">
        <p class="text-styles">
          Phiếu tính tiền chỉ có giá trị xuất hóa đơn GTGT trong vòng 24h tính
          từ ngày mua hàng.<br />
          Quý khách vui lòng liên hệ Cửa hàng để cung cấp thông tin xuất hóa đơn
          GTGT.
        </p>
        <p class="text-styles">Cảm ơn quý khách và hẹn gặp lại!</p>
      </div>
    </div>
  </div>
</template>
<script>
import { convertPrice } from '@/utils/common';
import { BILL_ITEM_TYPE, STORE_TYPE, DISCOUNT_AMOUNT_TYPE } from '@/utils/enum';
import VueQRCodeComponent from 'vue-qrcode-component';
import { featureFlagMixin } from '@/view/mixins';

export default {
  mixins: [featureFlagMixin],
  props: ['bill', 'productUsages'],
  data() {
    return {
      DISCOUNT_AMOUNT_TYPE,
      STORE_TYPE,
      BILL_ITEM_TYPE,
      linkHR: 'https://didongviet.vn/dchannel/muc-tuyen-dung/',
      linkDownloadApp: 'https://ddv.onelink.me/uPCc/s8wbgntz?af_qr=true',
      linkViewWarranty: 'https://didongviet.vn/',
      linkCampaignVF3:
        'https://didongviet.vn/dchannel/mua-san-pham-di-dong-tai-di-dong-viet-so-huu-xe-hoi-dien-vinfast-vf-3/',
      isVDDStore: this.bill.storeType === STORE_TYPE.VDD,
    };
  },
  methods: {
    convertPrice,
    // persentDiscountProduct({ listPrice, price }) {
    //   if (listPrice <= 0) return 0; // Tránh chia cho 0

    //   let discountPercentage = ((listPrice - price) / listPrice) * 100;
    //   return Math.max(0, discountPercentage).toFixed(2); // Giữ 2 số thập phân
    // },
    // percentDiscountProduct({
    //   listedPrice,
    //   price,
    //   discountAmount,
    //   discountType,
    //   customerDiscountType,
    //   customerDiscountAmount,
    // }) {
    //   if (listedPrice <= 0) return 0; // Tránh chia cho 0

    //   // Bước 1: Tính giá sau khi áp dụng discount
    //   let finalPrice = +price;

    //   // Nếu có discountAmount (chiết khấu trực tiếp)
    //   if (discountType === DISCOUNT_AMOUNT_TYPE.MONEY) {
    //     finalPrice -= +discountAmount;
    //   }
    //   if (discountType === DISCOUNT_AMOUNT_TYPE.PERCENT) {
    //     finalPrice -= (finalPrice * discountAmount) / 100;
    //   }

    //   // Nếu có chiết khấu khách hàng theo %
    //   if (customerDiscountType === 'percent') {
    //     finalPrice -= (finalPrice * customerDiscountAmount) / 100;
    //   }
    //   if (customerDiscountType === 'amount') {
    //     finalPrice -= +customerDiscountAmount;
    //   }

    //   // Đảm bảo giá không âm
    //   finalPrice = Math.max(0, finalPrice);

    //   // Bước 2: Tính phần trăm giảm giá
    //   let discountPercentage =
    //     ((+listedPrice - finalPrice) / listedPrice) * 100;
    //   console.log(listedPrice, finalPrice, listedPrice);

    //   return Math.max(0, discountPercentage).toFixed(2); // Giữ 2 số thập phân
    // },
    percentDiscountProduct({
      listedPrice,
      price,
      discountAmount,
      discountType,
      customerDiscountType,
      customerDiscountAmount,
      quantity,
    }) {
      if (listedPrice <= 0 || quantity <= 0) return 0; // Tránh lỗi chia cho 0 và số lượng không hợp lệ

      // Bước 1: Tính giá sau khi áp dụng discount cho 1 sản phẩm
      let finalPrice = +price;
      // Nếu có discountAmount (chiết khấu trực tiếp)
      if (discountType === DISCOUNT_AMOUNT_TYPE.MONEY) {
        finalPrice -= +discountAmount;
      }
      if (discountType === DISCOUNT_AMOUNT_TYPE.PERCENT) {
        finalPrice -= (finalPrice * discountAmount) / 100;
      }

      // Nếu có chiết khấu khách hàng theo %
      if (customerDiscountType === 'percent') {
        finalPrice -= (finalPrice * customerDiscountAmount) / 100;
      }
      if (customerDiscountType === 'amount') {
        finalPrice -= +customerDiscountAmount;
      }

      // Đảm bảo giá không âm
      finalPrice = Math.max(0, finalPrice);

      // Bước 2: Nhân số lượng để tính tổng giá gốc và tổng giá sau giảm
      let totalListedPrice = listedPrice * quantity;
      let totalFinalPrice = finalPrice * quantity;

      // Tính phần trăm giảm giá
      let discountPercentage =
        ((totalListedPrice - totalFinalPrice) / totalListedPrice) * 100;

      return Math.max(0, discountPercentage).toFixed(2); // Giữ 2 số thập phân
    },
    actualDiscountAmount({
      listedPrice,
      price,
      quantity,
      discountAmount,
      discountType,
      customerDiscountType,
      customerDiscountAmount,
    }) {
      if (listedPrice <= 0 || quantity <= 0) return 0; // Tránh lỗi chia cho 0

      // Bước 1: Tính giá niêm yết tổng cộng
      let totalListedPrice = listedPrice * quantity;
      console.log(quantity);
      // Bước 2: Tính giá sau khi giảm cho 1 sản phẩm
      let finalPrice = +price;

      // Nếu có giảm giá theo số tiền
      if (discountType === DISCOUNT_AMOUNT_TYPE.MONEY) {
        finalPrice -= +discountAmount;
      }

      // Nếu có giảm giá theo phần trăm
      if (discountType === DISCOUNT_AMOUNT_TYPE.PERCENT) {
        finalPrice -= (finalPrice * discountAmount) / 100;
      }

      // Nếu có chiết khấu khách hàng theo %
      if (customerDiscountType === 'percent') {
        finalPrice -= (finalPrice * customerDiscountAmount) / 100;
      }

      // Nếu có chiết khấu khách hàng theo số tiền
      if (customerDiscountType === 'amount') {
        finalPrice -= +customerDiscountAmount;
      }

      // Đảm bảo giá không âm
      finalPrice = Math.max(0, finalPrice);

      // Bước 3: Tính tổng giá sau giảm giá
      let totalFinalPrice = finalPrice * quantity;

      // Bước 4: Tính tổng số tiền đã giảm
      let totalDiscount = Math.max(0, totalListedPrice - totalFinalPrice);

      return totalDiscount;
    },
    // actualDiscountAmount({ listPrice, price }) {
    //   if (listPrice <= 0) return 0; // Tránh chia cho 0
    //   return Math.max(0, listPrice - price); // Đảm bảo giá trị không âm
    // },
  },
  created() {
    const billNumber = this.$route.query.id;
    this.linkViewWarranty = `https://ddvloyalty.page.link/?link=https://ddvloyalty.page.link/billInfo?billNumber=${billNumber}&apn=com.didongviet.loyalty`;
  },
  watch: {
    bill: {
      handler(newBill) {
        this.isVDDStore = newBill && newBill.storeType === STORE_TYPE.VDD;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    getPhoneNumberByStoreType() {
      return this.bill.storeHotline ? this.bill.storeHotline : '1800 6018';
    },
    isEnabledVF3QRCode() {
      return this.isFeatureActive('qr-code-vf3-car-bonus'); //method from feature flag mixin
    },
    getTotalTradein() {
      if (Object.keys(this.bill.tradeIn).length) {
        return this.bill.tradeIn.finalBuyingPrice || 0;
      }
      return 0;
    },
    totalDiscountAmount() {
      return (
        this.bill.sumTotalDiscount +
        // this.bill.discountAmount +
        this.bill.sumTotalDiscountListedToSellingPrice
      );
    },
    // tổng trước chiết khấu hóa đơn
    // totalAmountAfterDiscountBill() {
    //   return Math.max(this.bill.sumTotalAmount, 0);
    // },

    totalAmountAfterDiscount() {
      const totalAfterDiscount =
        this.bill.sumUnitAmount -
        (this.bill.sumTotalDiscount +
          this.bill.discountAmount +
          this.bill.sumTotalDiscountCustomer);
      return totalAfterDiscount > 0 ? totalAfterDiscount : 0;
    },
    isEnableHiddenDDVInfo() {
      return this.isFeatureActive('hidden-ddv-information');
    },
    showDDVInfo() {
      return !(
        (this.isVDDStore && this.isEnableHiddenDDVInfo) ||
        this.bill.storeName.includes('Vertu')
      );
    },
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
};
</script>
<style lang="css" scoped>
/* ================ Common ================ */
.tab {
  position: absolute;
  left: 10em;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.customer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.customer .text-title {
  min-width: 80px;
  display: inline-block;
  position: relative;
}

.customer .text-title::after {
  content: ':';
  position: absolute;
  right: -4px;
}

.text-bold-600 {
  font-weight: 600;
}

.list-product {
  display: contents;
}

.heading-logo {
  object-fit: contain;
}

.bill-content {
  font-weight: 500;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 0;
}

tr th {
  color: #000000;
  font-weight: 500;
}

tr td {
  color: #000000;
  font-weight: 500;
}

.heading {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.loyalty {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  margin-top: 0;
  /* gap: 1rem; */
}

.loyalty .text-title {
  min-width: 160px;
  display: inline-block;
  position: relative;
}

.loyalty .text-title::after {
  content: ':';
  position: absolute;
  right: 0;
}

hr {
  border: none;
  border-top: 2px dotted black;
  color: #fff;
  background-color: #fff;
  height: 2px;
}

hr.dotted {
  border: none;
  border-top: 1px dotted cadetblue;
  color: #fff;
  background-color: #fff;
  height: 1px;
}

.product-table {
  margin: 10px 0;
  width: 100%;
}

.product-table thead tr {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  line-height: 35px;
}

.product-table tfoot {
  border-top: 1px dashed black;
  border-bottom: unset;
  line-height: 28px;
}

.product-table tbody tr {
  line-height: 24px;
}

.list-product tr:last-child {
  border-bottom: 1px dashed black;
}

.text-bold {
  color: black;
  font-weight: 600;
}

.text-500 {
  color: black;
  font-weight: 500;
}

.text-align-center {
  text-align: center;
}

.text-styles {
  color: black;
  font-size: 12.5px;
  font-weight: 500;
}

.content-info {
  color: black;
  font-weight: 450;
}

.font-size-print {
  font-size: 14px;
}
.font-size-imei {
  font-size: 13px;
  font-weight: 400;
}

.content-product {
  line-height: 1.375;
  white-space: pre-wrap;
  font-size: 13px;
  margin-top: 4px;
}

.item-print {
  width: 97mm !important;
  margin-left: 16px !important;
  page-break-inside: avoid; /* Tránh cắt nội dung giữa trang */
  overflow: visible !important;
  /* width: 72mm !important; */

  /* margin-left: 30px !important; */
}

.text-download-app {
  color: black;
  font-weight: 500;
  margin-top: 4px;
  line-height: 1.3;
}

.campaign-vf3-text {
  font-weight: 1000;
}

.listed-price {
  text-decoration: line-through;
  color: gray;
  font-size: 14px;
}
@media print {
  * {
    overflow: visible !important;
  }

  table,
  div tr td {
    page-break-inside: avoid;
  }

  body {
    width: 100%;
    height: auto;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  tr .header-vdd {
    background: #000 !important;
    color: white !important;
  }

  tr .header-vdd td {
    border-left: 2px solid #fff !important;
  }
}
</style>
